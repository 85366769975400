import IframePlayer from "player-iframe-video";
interface Props {
  videoSourceUrl: string 
  id: string
}

export const VideoFrame = ({videoSourceUrl, id}: Props) => {

  const iFrame = (
    <iframe   
      width="100%" 
      height="100%"
      src={videoSourceUrl}
      frameBorder="0"
      allow="fullscreen"
      allowFullScreen
      title="ballong situationen" 
    />
  )

  return (
    <div style={wrapper}>
      <div style={innerWrapper}>
        <IframePlayer id={`video-${id}`} iFrame={iFrame} />
      </div>
    </div>
  )
}

 const wrapper: any = {
  position: "relative",
  overflow: "hidden",
  width: "100%",
  paddingTop: "56.25%",
}

const innerWrapper: any = {
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  width: "100%",
  height: "100%",
}




