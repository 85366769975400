import { Box, Typography,  Link } from "@mui/material";
import React, { ReactNode } from "react";
import { CSSProperties } from "react";
import logo from "../../assets/logo-dark.png"
import ErrorOutlineTwoToneIcon from '@mui/icons-material/ErrorOutlineTwoTone';

interface IState {
  hasError: boolean;
}

interface IProps {
  children: ReactNode;
}
class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box>
          <Box style={errorContainer}>
            <Box mb={10} display="flex" justifyContent="center">
              <ErrorOutlineTwoToneIcon 
                sx={{ 
                  color: 'red',
                  fontSize: 300
                }}
              />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h5">Något verkar ha gått fel.</Typography>
              <Link color="secondary" href="/">
                <Typography variant="h5">
                  Återgå till startsidan.
                </Typography>
              </Link>
            </Box>
          </Box>
        </Box>
      );
    }

    return this.props.children;
  }
}

const errorContainer: CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  height: "100vh",
  objectFit: "contain",
};

export default ErrorBoundary;
