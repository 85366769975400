import img1 from "../../assets/01.jpg"
import img2 from "../../assets/02.jpg"
import img3 from "../../assets/03.jpg"
import img4 from "../../assets/04.jpg"
import img5 from "../../assets/05.jpg"
import img6 from "../../assets/06.jpg"
import img7 from "../../assets/07.jpg"
import img8 from "../../assets/08.jpg"
import img9 from "../../assets/09.jpg"

import img10 from "../../assets/Tjanster_02.jpg"
import img11 from "../../assets/Tjanster_03.jpg"
import img12 from "../../assets/Tjanster_05.jpg"
import img13 from "../../assets/Tjanster_06.jpg"
import img14 from "../../assets/Tjanster_07.jpg"

//probably need to upload to fireBase instead eventually. 

export const images = [
    { url: img1 },
    { url: img2 },
    { url: img3 },
    { url: img4 },
    { url: img5 },
    { url: img6 },
    { url: img7 },
    { url: img8 },
    { url: img9 },
    { url: img10 },
    { url: img11 },
    { url: img12 },
    { url: img13 },
    { url: img14 },
]

//write a function that checks image width when uploaded and assigns correct values. 
export const photos = [
  { src: img1 },
  { src: img2 },
  { src: img3 },
  { src: img4 },
  { src: img5 },
  { src: img6 },
  { src: img7 },
  { src: img8 },
  { src: img9 },
  { src: img10 },
  { src: img11 },
  { src: img12 },
  { src: img13 },
  { src: img14 },
];

