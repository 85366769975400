import { useContext, useRef } from "react";
import { deviceSizeContext } from "../../contexts/deviceSizeContext";
import { CarouselContainer } from "./carousel"
import { EventCards } from "./eventCards";

export const LandingPage = () => {
  const { deviceSize } = useContext(deviceSizeContext);
  const scrollToRef = useRef();

  return (
    <div style={{marginTop: deviceSize === "mobile" ? "3.75em" : "5.2em"}}>
     <CarouselContainer scrollToRef={scrollToRef}/>
     <EventCards scrollToRef={scrollToRef}/>
    </div>
  )
}
