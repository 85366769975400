import React, { useContext } from 'react';
import Carousel from 'react-gallery-carousel';
import { deviceSizeContext } from '../../contexts/deviceSizeContext';
import { photos } from '../tempFiles/carouselImgs';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface Props {
  eventSlider?: Array<{ src: string }>;
}

export const ImageGallery = ({ eventSlider }: Props) => {
  const { deviceSize } = useContext(deviceSizeContext);

  if (eventSlider) {
    return (
      <div>
        <Carousel
          leftIcon={
            <div style={chevronLeftStyle}>
              <ArrowBackIosIcon sx={{ fontSize: '2em', color: 'white', marginLeft: '0.8rem' }} />
            </div>
          }
          rightIcon={
            <div style={chevronRightStyle}>
              <ArrowForwardIosIcon sx={{ fontSize: '2em', color: 'white' }} />
            </div>
          }
          images={eventSlider}
          style={carouselStyleEvent(deviceSize)}
          playIcon={false}
          hasIndexBoard={false}
          hasThumbnails={false}
          hasSizeButton={false}
          canAutoPlay
          isAutoPlaying
          autoPlayInterval={4000}
          hasTransition
        />
      </div>
    );
  }

  return (
    <div style={galleryStyle(deviceSize)}>
      <Carousel
        leftIcon={
          <div style={chevronLeftStyle}>
            <ArrowBackIosIcon sx={{ fontSize: '2em', color: 'white', marginLeft: '1rem' }} />
          </div>
        }
        rightIcon={
          <div style={chevronRightStyle}>
            <ArrowForwardIosIcon sx={{ fontSize: '2em', color: 'white' }} />
          </div>
        }
        images={photos}
        style={carouselStyle(deviceSize)}
        objectFit="contain"
        objectFitAtMax="contain"
        thumbnailWidth={deviceSize === 'desktop' ? '14%' : deviceSize === 'tablet' ? '16%' : '18%'}
        thumbnailHeight={deviceSize === 'desktop' ? '16%' : deviceSize === 'tablet' ? '12%' : '12%'}
        playIcon={false}
        hasIndexBoard={false}
      />
    </div>
  );
};

const chevronLeftStyle: React.CSSProperties = {
  backgroundColor: 'black',
  borderRadius: '50%',
  width: '3rem',
  height: '3rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '1rem',
};

const chevronRightStyle: React.CSSProperties = {
  backgroundColor: 'black',
  borderRadius: '50%',
  width: '3rem',
  height: '3rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '1rem',
};

const galleryStyle = (deviceSize: string) => {
  const style: React.CSSProperties = {
    marginTop: deviceSize === 'mobile' ? '3.8em' : '5.2em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  };
  return style;
};

const carouselStyle = (size: string) => {
  const style: React.CSSProperties = {
    height: size === 'desktop' ? 1000 : size === 'tablet' ? 900 : 600,
    width: '100%',
    zIndex: 0,
  };
  return style;
};

const carouselStyleEvent = (size: string) => {
  const style: React.CSSProperties = {
    height: size === 'desktop' ? '100vh' : size === 'tablet' ? 600 : 300,
    width: '100%',
    zIndex: 0,
  };
  return style;
};
