import { createContext, ReactNode, useEffect, useState } from 'react';

interface ContextValue {
  deviceSize: string;
}

export const deviceSizeContext = createContext<ContextValue>({
  deviceSize: '',
});

interface Props {
  children: ReactNode;
}

function DeviceSizeProvider(props: Props) {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
  }, []);

  const calculateViewableSize = () => {
    const width = dimensions.width;
    // Determnies the estimated screen size of the client device based on: https://www.w3schools.com/browsers/browsers_display.asp
    if (width >= 1366) {
      return 'desktop';
    } else if (width >= 768) {
      return 'tablet';
    } else {
      return 'mobile';
    }
  };

  return (
    <deviceSizeContext.Provider
      value={{
        deviceSize: calculateViewableSize(),
      }}
    >
      {props.children}
    </deviceSizeContext.Provider>
  );
}

export const deviceSizeConsumer = deviceSizeContext.Consumer;
export default DeviceSizeProvider;
