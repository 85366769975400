import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { deviceSizeContext } from '../../contexts/deviceSizeContext';
import image from '../../assets/Oss_01_850.jpg';

export const AboutPage = () => {
  const { deviceSize } = useContext(deviceSizeContext);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: deviceSize === 'mobile' ? '3.8em' : '5.2em',
      }}
    >
      <Box style={container}>
        <img alt={`Om oss`} src={image} style={imgStyle(deviceSize)} />

        <div style={cardContainer(deviceSize)}>
          <div
            style={{
              width: deviceSize === 'mobile' ? '100%' : '50%',
              display: 'flex',
              justifyContent: deviceSize === 'mobile' ? 'center' : 'flex-end',
              paddingRight: '4rem',
            }}
          >
            <Typography
              variant={deviceSize === 'mobile' ? 'h4' : 'h3'}
              color="primary.dark"
              style={{ paddingBottom: deviceSize === 'mobile' ? '0.5em' : '0em' }}
            >
              OM OSS
            </Typography>
          </div>
          <div style={{ width: deviceSize === 'mobile' ? '100%' : '50%' }}>
            <Typography color="primary.dark">
              Imago Ballonger & Dekor är ett av Sveriges främsta ballongdekorföretag. Vi
              specialiserar oss på att skapa skräddarsydda ballonginstallationer för stora ytor så
              som eventlokaler, mässor och köpcenter, likväl som unika dekorlösningar till
              företagsfester, marknadsaktiviteter och butikskedjor. Vi är baserade i Göteborg men
              tar ofta uppdrag över hela Sverige. Hör av dig!
            </Typography>
          </div>
        </div>
      </Box>
    </div>
  );
};

const container: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '100%',
  width: '100%',
};

const cardContainer = (size: string) => {
  const style: React.CSSProperties = {
    display: 'flex',
    flexDirection: size === 'mobile' ? 'column' : 'row',
    paddingBottom: '2em',
    flexBasis: 1,
    width: size === 'mobile' ? '80%' : '60%',
  };
  return style;
};

const imgStyle = (size: string) => {
  const style: React.CSSProperties = {
    objectFit: 'cover',
    width: '100%',
    height: size === 'mobile' ? 'calc(30vh)' : 'calc(100vh - 5.2em)',
    display: 'flex',
    paddingBottom: '2em',
    overflow: 'hidden',
  };
  return style;
};
