import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { deviceSizeContext } from '../../contexts/deviceSizeContext';
import { tempCards } from '../tempFiles/cards';

interface Props {
  scrollToRef: any;
}

export const EventCards = ({ scrollToRef }: Props) => {
  const { deviceSize } = useContext(deviceSizeContext);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }} ref={scrollToRef}>
      <div style={cardsContainer}>
        {tempCards.map((card, i) => {
          return (
            <a
              key={`${i}-key`}
              style={{ color: 'inherit', textDecoration: 'inherit' }}
              href={card.link ? `https://${card.link}` : `/event/${card.id}`}
              target={card.link ? '_blank' : '_self'}
              rel="noopener noreferrer"
            >
              <Card
                style={{ border: 'none', boxShadow: 'none' }}
                variant="outlined"
                sx={{
                  height: 350,
                  width: deviceSize === 'mobile' ? 0 : 500,
                  minWidth: 300,
                  margin: '1em',
                }}
                square={true}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="250"
                    image={card.img}
                    alt={`card ${card.pageTitle}`}
                  />
                  <CardContent sx={{ padding: 0 }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{ paddingTop: '0.5rem' }}
                    >
                      {card.thumbNailTitle.toUpperCase()}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      sx={{ marginTop: '-0.5rem', fontWeight: 'bold' }}
                    >
                      {card.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </a>
          );
        })}
      </div>
    </div>
  );
};

const cardsContainer: React.CSSProperties = {
  zIndex: '1 !important',
  padding: '1.5rem 1rem 2rem 1.5rem',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  maxWidth: '100rem',
};
