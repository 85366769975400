import MenuIcon from '@mui/icons-material/Menu';
import { Box, Link } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Toolbar from '@mui/material/Toolbar';
import React, { useState } from 'react';
import logo from '../../assets/logo-dark.png';
import { tempCards } from '../tempFiles/cards';
import { navText } from './navBar';

export const MobileNavBar = () => {
  const [state, setState] = useState({ top: false });

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState({ top: open });
  };

  const list = () => (
    <Box
      sx={{ width: 'auto' }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {navText.map((text, index) => (
          <ListItem key={text.name}>
            <Link href={text.link} style={{ textDecoration: 'none', color: ' #343432' }}>
              <ListItemText primary={text.name} />
            </Link>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {tempCards.map((card, index) => (
          <ListItem key={card.thumbNailTitle}>
            <Link
              href={card.link ? `https://${card.link}` : `/event/${card.id}`}
              style={{ textDecoration: 'none', color: ' #343432' }}
              target={card.link ? '_blank' : '_self'}
            >
              <ListItemText primary={card.thumbNailTitle} />
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ backgroundColor: '#ebebeb' }}>
        <Toolbar variant="dense">
          <Link href={'/'} sx={{ flexGrow: 1 }}>
            <img src={logo} alt="logo" style={logoStyleMobile} />
          </Link>
          <IconButton size="large" edge="start" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon style={{ color: '#343432' }} />
          </IconButton>
          <SwipeableDrawer
            anchor={'top'}
            open={state['top']}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            {list()}
          </SwipeableDrawer>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

const logoStyleMobile: React.CSSProperties = {
  height: '2.5em',
  padding: '0.5em 0.5em 0.5em 0.5em',
};
