import { Box, Link, Typography } from '@mui/material';
import React, { useState } from 'react';
import { tempCards } from '../tempFiles/cards';
interface Props {
  collapseDropDownBar: () => void;
}

export const DropDownLinks = ({ collapseDropDownBar }: Props) => {
  const [hover, setHover] = useState<boolean>(false);
  const [currentEventLink, setCurrentEventLink] = useState<string>('');

  return (
    <Box
      sx={{ zIndex: 'modal' }}
      style={navBarStyle}
      onMouseLeave={() => {
        setCurrentEventLink(' ');
      }}
    >
      <Box style={navigationStyle}>
        {tempCards.map((card, i) => {
          return (
            <Typography
              key={`${i}-key`}
              onMouseEnter={() => {
                setHover(true);
                setCurrentEventLink(card.thumbNailTitle);
              }}
              onMouseLeave={() => {
                setHover(false);
              }}
            >
              <Link
                href={card.link ? `https://${card.link}` : `/event/${card.id}`}
                style={navLinksStyle(hover, currentEventLink, card.thumbNailTitle)}
                target={card.link ? '_blank' : '_self'}
              >
                {card.thumbNailTitle.toUpperCase()}
              </Link>
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
};

const navLinksStyle = (hover: boolean, currentEventLink: string, text: string) => {
  const mouseOverStyle: React.CSSProperties = {
    color: hover && currentEventLink === text ? '#fbda33' : '#ebebeb',
    textDecoration: 'none',
    margin: '1.5em',
  };
  return mouseOverStyle;
};

const navigationStyle: React.CSSProperties = {
  padding: '1em 2em 1em 1em',
  display: 'flex',
  alignItems: 'center',
};

const navBarStyle: React.CSSProperties = {
  transition: 'transform 0.2s ease-in-out',
  display: 'flex',
  justifyContent: 'space-between',
  position: 'absolute',
  width: '100%',
  marginTop: '5.2em',
  backgroundColor: '#c0388d',
};
