import img2 from '../../assets/Events_01.jpg';
import img3 from '../../assets/Organisk_01_850.jpg';
import img5 from '../../assets/Pompallong_01_850.jpg';
/* import img6 from '../../assets/Tjanster_06.jpg';
import img7 from '../../assets/Tjanster_07.jpg'; */
import img8 from '../../assets/webshop.jpg';

import secondary_2_1 from '../../assets/entre_01.jpg';
import secondary_2_2 from '../../assets/entre_02.jpg';
import secondary_2_3 from '../../assets/entre_03.jpg';
import secondary_2_4 from '../../assets/entre_04.jpg';
import secondary_2_5 from '../../assets/entre_05.jpg';

import secondary_2_6 from '../../assets/Organisk_02.jpg';
import secondary_2_7 from '../../assets/Organisk_03.jpg';
import secondary_2_8 from '../../assets/Organisk_04.jpg';
import secondary_2_9 from '../../assets/Organisk_05.jpg';

import secondary_2_10 from '../../assets/Pompallong_02.jpg';
import secondary_2_11 from '../../assets/Pompallong_03.jpg';
import secondary_2_12 from '../../assets/Pompallong_04.jpg';
import secondary_2_13 from '../../assets/Pompallong_05.jpg';
import secondary_2_14 from '../../assets/Pompallong_06.jpg';

export const tempCards = [
  {
    id: '1',
    img: img2,
    thumbNailTitle: 'EVENT',
    pageTitle: 'EN SKRÄDDARSYDD UPPLEVELSE',
    description:
      'Har du märkt att ballonger på något sätt har blivit mer glamorösa? Det har vi också!',
    longDescription:
      'Att skapa en minnesvärd visuell upplevelse är verkligen en konstform. Dina gäster – eller dina kunders gäster – vill känna sig inspirerade. Och förtjusta. Och överväldigade. Och de kommer alltid att minnas vem det var som fick dem att känna just så.',
    video: 'https://player.vimeo.com/video/208307300?h=8dc6e55d52&amp',
    secondarySlider: undefined,
    link: undefined,
  },
  {
    id: '2',
    img: secondary_2_1,
    thumbNailTitle: 'ENTRÉDEKORATIONER',
    pageTitle: 'FÖRSTA INTRYCKET RÄKNAS',
    description: 'Tidslös ballongdekor som får alla att känna sig välkommna.',
    longDescription:
      'Inom 10-40 sekunder har hela 90% av oss bildat oss en uppfattning om det första intrycket. Som tur är kommer du långt även med små medel. Kostnadseffektiva ballongklassiker är lätta att skräddarsy och ett utmärkt sätt att väcka den där första känslan av förtjusning hos besökare.',
    video: undefined,
    secondarySlider: [
      { src: secondary_2_2 },
      { src: secondary_2_3 },
      { src: secondary_2_4 },
      { src: secondary_2_5 },
    ],
    link: undefined,
  },
  {
    id: '3',
    img: img3,
    thumbNailTitle: 'ORGANISK DESIGN',
    pageTitle: 'NATURLIG IMITATION',
    description: 'Skapade med inspiration från naturens mönster och former.',
    longDescription:
      'Till skillnad från våra klassiska ballongbågar- och pelare, som skapas med perfekta arkitektoniska mått, är våra organiska ballongarrangemang baserade på naturens egna former och rörelser. Organiska ballonger är perfekta när man är ute efter något modernt, något som skilljer sig från våra klassiska lösningar.',
    video: undefined,
    secondarySlider: [
      { src: secondary_2_6 },
      { src: secondary_2_7 },
      { src: secondary_2_8 },
      { src: secondary_2_9 },
    ],
    link: undefined,
  },
  {
    id: '4',
    img: img5,
    thumbNailTitle: 'POMPALLONGER',
    pageTitle: 'POMPALLONGER',
    description:
      'Jätteballonger på stjälk som svajar i vinden och skapar ett oförglömligt intryck.',
    longDescription:
      'Pompallonger är en jätteballonglösning som designats för att fungera utan helium. De hålls samman av en flexibel stång som fixeras i en stabil bas. Resultatet blir en stilla svajande rörelse som är omöjlig att uppnå utomhus med hjälp av traditionella lösningar. Dessa självstående jätteballonger är även en del av vår miljösatsning då de hjälper oss att minska användandet av helium.',
    video: undefined,
    secondarySlider: [
      { src: secondary_2_10 },
      { src: secondary_2_11 },
      { src: secondary_2_12 },
      { src: secondary_2_13 },
      { src: secondary_2_14 },
    ],
    link: undefined,
  },
  {
    id: '5',
    img: img8,
    thumbNailTitle: 'WEBBSHOP',
    pageTitle: 'WEBBSHOP',
    description: 'Fyll kundvagnen med kvalitetsballonger i olika storlekar och färger!',
    longDescription: '',
    video: undefined,
    secondarySlider: undefined,
    link: 'webbshop.imagoballonger.se/',
  },
  /*   {
    id: '6',
    img: img6,
    thumbNailTitle: 'MÄSSOR',
    pageTitle: 'MÄSSOR',
    description: 'Det går faktiskt att få ditt monter att stick ut i mängden',
    longDescription:
      'Imago Ballonger & Dekor är ett av Sveriges främsta ballongdekorföretag. Vi specialiserar oss på att skapa skräddarsydda ballonginstallationer för stora ytor så som museum, teatrar, konvent och shoppingcenter, likväl som unika dekorlösningar till företagsfester, marknadsaktiviteter och butikskedjor',
    video: undefined,
    secondarySlider: undefined,
  },
  {
    id: '7',
    img: img7,
    thumbNailTitle: 'BALLONGTRYCK',
    pageTitle: 'BALLONGTRYCK',
    description: 'Ditt budskap plus uppmärksamhet! Varför ändra på ett vinnande koncept?',
    longDescription:
      'Imago Ballonger & Dekor är ett av Sveriges främsta ballongdekorföretag. Vi specialiserar oss på att skapa skräddarsydda ballonginstallationer för stora ytor så som museum, teatrar, konvent och shoppingcenter, likväl som unika dekorlösningar till företagsfester, marknadsaktiviteter och butikskedjor',
    video: undefined,
    secondarySlider: undefined,
  }, */
];
