import { Box, Link, Typography } from "@mui/material"
import React, { useContext, useState } from "react"
import logo from "../../assets/logo-dark.png"
import { DropDownLinks } from "./dropDownLinks";
import { deviceSizeContext } from "../../contexts/deviceSizeContext";
import { MobileNavBar } from "./mobileNavBar";

export const navText = [ 
  { 
    name:"TJÄNSTER", 
    link: "/"
  } , 
  {
    name: "GALLERI",
    link: "/gallery"
  } , 
  {
    name: "OM OSS",
    link: "/about"
  } 
]

export const NavBar= () => {
  const { deviceSize } = useContext(deviceSizeContext);
  const [hover, setHover] = useState<boolean>(false)
  const [currentNavLink, setCurrentNavLink] = useState<string>("")

  const collapseDropDownBar = () => {
    setCurrentNavLink("")
  }

  if(deviceSize === "mobile"){
    return <MobileNavBar />
  }

  return (
      <Box  style={navBarStyle} >
        <Link href={"/"}>
          <img src={logo} alt="logo" style={logoStyle}/>
        </Link>
          <Box  style={navigationStyle}>
            {navText.map((navInfo, i) => {
              return (
                <Typography 
                  color="primary"
                  key={`text-${i}`}
                  onMouseEnter={() => { 
                    setHover(true) 
                    setCurrentNavLink(navInfo.name)
                  }}
                  onMouseLeave={() => {
                    setHover(false)
                  }}
                  variant={"h6"}
                >
                  <Link href={navInfo.link} style={navLinksStyle(hover, currentNavLink, navInfo.name)}>{navInfo.name}</Link>
                </Typography>
              ) 
            })}
          </Box>
          {currentNavLink === "TJÄNSTER" ? <DropDownLinks collapseDropDownBar = {collapseDropDownBar}/> : null}
      </Box>

  )
}

const navLinksStyle = (hover: boolean, hovered: string, text: string) => {
  const mouseOverStyle: React.CSSProperties = {
    color:hover && hovered === text ? "#c0388d" : "#343432",
    textDecoration: "none",
    margin: "0.8em",
  }
  return mouseOverStyle
}

const navigationStyle: React.CSSProperties = {
  padding: "1em 2em 1em 1em",
  display: "flex",
  alignItems: "center"
}

const logoStyle: React.CSSProperties = {
  height: "3em",
  padding: "1em 1em 1em 2em"
}

const navBarStyle: React.CSSProperties = {
  zIndex: "tooltip",
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  backgroundColor: "white"
}