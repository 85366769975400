import AliceCarousel from 'react-alice-carousel';
import { images } from "../tempFiles/carouselImgs";
import "react-alice-carousel/lib/alice-carousel.css";
import 'react-gallery-carousel/dist/index.css';
import { useContext } from 'react';
import { deviceSizeContext } from '../../contexts/deviceSizeContext';
import { Button, Fab } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props {
  scrollToRef: any
}

export const CarouselContainer = ({scrollToRef}:Props) => {
  const {deviceSize} = useContext(deviceSizeContext)
  
  const responsive = {
    0: { items: 1 },
    1024: { items: 1 },
  }

  function handleScroll() {
    scrollToRef.current.scrollIntoView({behavior: 'smooth'});
  }

  const image = (img: {url: string}, i: number) => {
    return (
      <div style={containerStyle} key={`img-${i}`}>
        <img src={img.url} className="sliderimg" style={imgStyle(deviceSize)} alt={`${img.url}`} key={i}/> 
        <div style={centeredContent}>
          <Button variant="outlined" size="large" style={{color: "#ebebeb", borderColor: "#ebebeb", backgroundColor: "rgba(52,52,50, .5)"}} onClick={handleScroll}>VÅRA TJÄNSTER</Button>
          <Fab color="secondary" size="large" aria-label="add" style={{marginTop: "3em", border: "2px solid #ebebeb"}} onClick={handleScroll}>
            <ExpandMoreIcon style={{fontSize: "3em"}}/>
          </Fab>
        </div>
      </div>
    )
  }

  return (
    <div>
      <AliceCarousel 
        infinite
        autoPlay={true}
        autoPlayInterval={2000}
        animationDuration={1000}
        responsive={responsive}
        disableDotsControls
        disableButtonsControls
        animationType={"fadeout"}
        touchTracking={false}
      >
        {images.map((img, i) => image(img, i))}
      </AliceCarousel>
    </div>
  )
} 

const containerStyle: React.CSSProperties = {
    textAlign: 'center',
    color: '#ebebeb',
}

const centeredContent: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: 'absolute',
  top: '80%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}

const imgStyle = (size: string) => {
  const style: React.CSSProperties = {
    objectFit: "cover",
    width: "100%",
    height: size === "mobile" ? 'calc(100vh - 3.75em)' : 'calc(100vh - 5.2em)',
  }
  return style
}
