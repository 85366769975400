import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Box, Fab, Typography } from '@mui/material';
import React from 'react';

export const Footer = () => {
  return (
    <>
      <div style={{ height: '2.5rem', backgroundColor: '#e0dfda' }} />
      <div style={footerStyle}>
        <Box style={{ display: 'flex', padding: '1rem' }}>
          <Fab
            style={socialMediaLinks}
            color="secondary"
            size="large"
            aria-label="add"
            href="https://www.facebook.com/imagoballonger"
            target="_blank"
          >
            <FacebookIcon />
          </Fab>
          <Fab
            style={socialMediaLinks}
            color="secondary"
            size="large"
            aria-label="add"
            href="https://www.instagram.com/imagoballonger/"
            target="_blank"
          >
            <InstagramIcon />
          </Fab>
        </Box>
        <div style={footerText}>
          <a style={{ textDecoration: 'none' }} href="mailto:info@imagoballonger.se">
            <Typography style={{ color: '#ebebeb' }}>info@imagoballonger.se</Typography>
          </a>
          <a style={{ textDecoration: 'none' }} href="tel:0760353452">
            <Typography style={{ color: '#ebebeb' }}>0760 35 34 52</Typography>
          </a>
        </div>
        <div style={footerText}>
          <Typography style={{ color: '#fbda33', marginTop: '1rem', fontSize: '0.6em' }}>
            Website built by ASAF LÄCKGREN
          </Typography>
        </div>
      </div>
    </>
  );
};

const socialMediaLinks: React.CSSProperties = {
  margin: '0.4em',
  border: '2.5px solid #ebebeb',
};

const footerText: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const footerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  backgroundColor: '#343432',
  height: '11em',
  padding: '1rem 0rem 1rem 0rem',
};
