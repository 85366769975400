import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { tempCards } from '../tempFiles/cards';
import { useParams } from 'react-router-dom';
import { deviceSizeContext } from '../../contexts/deviceSizeContext';
import { ImageGallery } from '../gallery/gallery';
import { VideoFrame } from './videoComponent';

export const EventView = () => {
  const { deviceSize } = useContext(deviceSizeContext);
  const params = useParams<{ id: string }>();
  const detailViewEvent = tempCards.find((e) => e.id === params.id);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: deviceSize === 'mobile' ? '3.8em' : '5.2em',
      }}
    >
      <Box style={container}>
        <img
          alt={`${detailViewEvent.img}-img`}
          src={detailViewEvent.img}
          style={imgStyle(deviceSize)}
        />

        <div style={cardContainer(deviceSize)}>
          <div
            style={{
              width: deviceSize === 'mobile' ? '100%' : '50%',
              display: 'flex',
              justifyContent: deviceSize === 'mobile' ? 'center' : 'flex-end',
              paddingRight: '4rem',
            }}
          >
            <Typography
              variant={deviceSize === 'mobile' ? 'h4' : 'h3'}
              color="primary.dark"
              style={{ paddingBottom: deviceSize === 'mobile' ? '0.5em' : '0em' }}
            >
              {detailViewEvent.pageTitle.toUpperCase()}
            </Typography>
          </div>
          <div style={{ width: deviceSize === 'mobile' ? '100%' : '50%' }}>
            <Typography color="primary.dark">{detailViewEvent.longDescription}</Typography>
          </div>
        </div>
        {detailViewEvent.secondarySlider ? (
          <ImageGallery eventSlider={detailViewEvent.secondarySlider} />
        ) : null}
        {detailViewEvent.video ? (
          <VideoFrame videoSourceUrl={detailViewEvent.video} id={params.id} />
        ) : null}
      </Box>
    </div>
  );
};

const container: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '100%',
  width: '100%',
};

const cardContainer = (size: string) => {
  const style: React.CSSProperties = {
    display: 'flex',
    flexDirection: size === 'mobile' ? 'column' : 'row',
    paddingBottom: '2em',
    flexBasis: 1,
    width: size === 'mobile' ? '80%' : '60%',
  };
  return style;
};

const imgStyle = (size: string) => {
  const style: React.CSSProperties = {
    objectFit: 'cover',
    width: '100%',
    height: size === 'mobile' ? 'calc(30vh)' : 'calc(100vh - 5.2em)',
    display: 'flex',
    paddingBottom: '2em',
    overflow: 'hidden',
  };
  return style;
};
