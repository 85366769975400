import { LandingPage } from './components/landingPage/landingPage';
import { Footer } from './components/footer/footer';
import { AboutPage } from './components/aboutUs/about';
import { PageNotFound } from './components/errorBoundary/pageNotFound';
import { ImageGallery } from './components/gallery/gallery';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Box, createTheme, ThemeProvider } from '@mui/material';
import ErrorBoundary from './components/errorBoundary/errorBoundary';
import { NavBar } from './components/navBar/navBar';
import DeviceSizeProvider from './contexts/deviceSizeContext';
import { EventView } from './components/eventPage/event';

function App() {
  const urlPathName: string = window.location.pathname;

  const theme = createTheme({
    palette: {
      primary: {
        dark: '#343432',
        main: '#c0388d',
        light: '#ebebeb',
        contrastText: '#fbda33',
      },
      secondary: {
        dark: '#343432',
        main: 'transparent',
        light: '#ebebeb',
        contrastText: '#ebebeb',
      },
    },
    typography: {
      fontFamily: ['FuturaMedium', 'FuturaHeavy', 'Raleway', 'Arial'].join(','),
      h5: { fontFamily: 'FuturaHeavy' },
      h6: { fontFamily: 'FuturaHeavy' },
      h3: { fontSize: '2.5em' },
    },
  });

  // #c0388d pink
  // #fbda33 yellow
  // #343432 grey
  // #ebebeb white

  return (
    <Box style={landingPage}>
      <ThemeProvider theme={theme}>
        <DeviceSizeProvider>
          <ErrorBoundary>
            <div style={{ position: 'absolute', width: '100%', zIndex: '9999 !important' }}>
              <NavBar />
            </div>
            <Box
              style={
                urlPathName === '/gallery' ? contentContainerGalleryView : contentContainerOtherView
              }
            >
              <Router>
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path={`/about`} element={<AboutPage />} />
                  <Route path={`/gallery`} element={<ImageGallery />} />
                  <Route path={'/event/:id'} element={<EventView />} />
                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </Router>
            </Box>
            <Footer />
          </ErrorBoundary>
        </DeviceSizeProvider>
      </ThemeProvider>
    </Box>
  );
}

export default App;

const contentContainerGalleryView: React.CSSProperties = {
  minHeight: 'calc(100vh - 11em)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  /* backgroundColor: "black" */
};

const contentContainerOtherView: React.CSSProperties = {
  minHeight: 'calc(100vh - 11em)',
  backgroundColor: '#ffffff',
};

const landingPage: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};
