
import { Box, CircularProgress, Typography, Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CSSProperties } from "react";


export const PageNotFound = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  if (isLoading) {
    return (
      <Box
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box>
      <Box style={errorContainer}>
        <Box mb={10} display="flex" justifyContent="center">
          <img style={imageStyling} alt="EatApp Logotype" />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box mb={3}>
          </Box>
          <Typography variant="h2">404</Typography>
          <Typography variant="h5">
            Oj, denna sidan verkar inte existera.
          </Typography>
          <Link color="secondary" href="/">
            <Typography variant="h5">Återgå till startsidan.</Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

const errorContainer: CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  objectFit: "contain",
};

const imageStyling: CSSProperties = {
  width: "70%",
};